<form [formGroup]="form">

  <mat-form-field appearance="outline">
    <mat-label for="date">Date</mat-label>
    <input matInput placeholder="Date" type="date" id="date" formControlName="date" [value]="form.get('date').value">

  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label for="text">Add a description of the case</mat-label>
    <input matInput placeholder="Add a description of the case" type="text" id="text" formControlName="notes">
  </mat-form-field>

  <mat-form-field appearance="fill" id="patientForm">
    <mat-label>Select Patient</mat-label>
    <mat-select formControlName="patient" name="patient" [compareWith]="compare">
      <mat-option [value]="null">None</mat-option>
      <mat-option *ngFor="let patient of patientsfromDB" [value]="patient">
        <mat-icon *ngIf="patient.gender === 'male'">male</mat-icon>
        <mat-icon *ngIf="patient.gender === 'female'">female</mat-icon>
        {{ patient.firstname }} {{ patient.lastname }}
      </mat-option>
    </mat-select>
  </mat-form-field>





  <button color="primary" mat-raised-button [color]="form.valid ? 'primary' : 'default'" [disabled]="!form.valid"
    (click)="submit()" type="submit">Submit</button>



</form>