<app-auth-box title="Sign Up">
  <form #form="ngForm">
    <mat-form-field appearance="fill">
        <mat-label>Email</mat-label>
        <input matInput type="email" [(ngModel)]="email.value" name="email" #email>
        <mat-error>{{emailErrorMessage}}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>Password</mat-label>
        <input matInput type="password" [(ngModel)]="password.value" name="password" #password>
        <mat-error>{{passwordErrorMessage}}</mat-error>
    </mat-form-field>
    <mat-error *ngIf="generalErrorMessage" class="general-error">{{generalErrorMessage}}</mat-error>
  </form>
  <div class="button-container">
      <button mat-raised-button color="primary" (click)="authService.SignUp(email.value, password.value)">
        <ng-container *ngIf="!authService.loading">
            Continue
        </ng-container>
        <mat-spinner *ngIf="authService.loading" diameter="30"></mat-spinner>
      </button>
      <button mat-button routerLink="/login">Go to Sign In</button>
  </div>
</app-auth-box>


