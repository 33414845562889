<div class="main" *ngIf="user" (click) ="$event.stopPropagation()">
    <div>
      <div class="media">
        <!-- <img class="align-self-start mr-5 img-thumbnail rounded-circle" src="{{(user.photoURL) ? user.photoURL : '/assets/dummy-user.png'}}"
          alt="{{user.displayName}}"> -->
        <div class="media-body">
          <h2>Hello: <strong>{{(user.displayName) ? user.displayName : 'User'}}</strong></h2>
          <p>User ID: <strong>{{user.uid}}</strong></p>
          <p>Email: <strong>{{user.email}}</strong></p>
          <p>Email Verified: <strong>{{user.emailVerified}}</strong></p>
        </div>
      </div>
    </div>
</div>