<div>
    <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
    </mat-form-field>
              
    <table style="width: 100%" mat-table [dataSource]="dataSource" matSort class="mat-elevation-z4">
        <!-- name column -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>
        <!-- value column -->
        <ng-container matColumnDef="value">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Value </th>
            <td mat-cell *matCellDef="let element"> {{element.value}} </td>
        </ng-container>
        <!-- row template -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</div>
