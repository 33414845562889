<main>
  <section class="tableContainer">
    <section class="btnCentered">
      <form class="example-form">
        <mat-form-field class="searchBar">
          <mat-icon matPrefix>search</mat-icon>
          <input matInput placeholder="Search Patient" [(ngModel)]="searchText" name="search" (input)="search()">
        </mat-form-field>
      </form>
      <button color="primary" mat-raised-button (click)="addPatient()" id="addPatientButton">Add Patient</button>
    </section>
    <ng-container *ngIf="loading">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </ng-container>
    <div class="mat-elevation-z4">
      <table mat-table [dataSource]="dataSource" >

        <ng-container matColumnDef="FirstName">
          <th mat-header-cell *matHeaderCellDef>
            First Name
          </th>
          <td mat-cell *matCellDef="let patient"> {{patient.firstname}} </td>
        </ng-container>
  
        <ng-container matColumnDef="LastName">
          <th mat-header-cell *matHeaderCellDef>
            Last Name
          </th>
          <td mat-cell *matCellDef="let patient"> {{patient.lastname}} </td>
        </ng-container>
  
        <ng-container matColumnDef="Email">
          <th mat-header-cell *matHeaderCellDef>
            Email
          </th>
          <td mat-cell *matCellDef="let patient"> {{patient.email}} </td>
        </ng-container>
  
  
        <ng-container matColumnDef="Age">
          <th mat-header-cell *matHeaderCellDef>
            Age
          </th>
          <td mat-cell *matCellDef="let patient"> {{patient.age}} </td>
        </ng-container>
  
        <ng-container matColumnDef="Bmi">
          <th mat-header-cell *matHeaderCellDef>
            BMI
          </th>
          <td mat-cell *matCellDef="let patient"> {{patient.bmi}} </td>
        </ng-container>
  
        <ng-container matColumnDef="Gender">
          <th mat-header-cell *matHeaderCellDef>
            Gender
          </th>
          <td mat-cell *matCellDef="let patient"> {{patient.gender}} </td>
        </ng-container>
  
        <ng-container matColumnDef="menu">
          <th mat-header-cell *matHeaderCellDef class="maxWidthCell"></th>
          <td mat-cell *matCellDef="let patient">
            <ng-container *ngIf="patient">
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="editPatient(patient)">
                  <mat-icon>edit</mat-icon>
                  <span>Edit</span>
                </button>
                <button mat-menu-item (click)="deleteSafe(patient)">
                  <mat-icon>delete</mat-icon>
                  <span>Delete</span>
                </button>
                <button mat-menu-item (click)="viewPatient(patient)" ng-model="checked">
                  <mat-icon>visibility</mat-icon>
                  <span>View</span>
                </button>
              </mat-menu>
            </ng-container>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="['FirstName','LastName','Email','Age','Bmi','Gender','menu']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['FirstName','LastName','Email','Age','Bmi','Gender','menu'];"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell no-data-cell" [attr.colspan]="7">
            No patient data found
          </td>
        </tr>
      </table>
      <mat-paginator [pageSizeOptions]="[25, 100, 200,500,1000]" showFirstLastButtons
        aria-label="Select page of patients">
      </mat-paginator>
    </div>
    
  </section>
</main>