<div class="main-container">

    <div *ngIf="!predictionResult">
        <div class="topbar" style="margin-top: 16px;">
            <h2 style="margin-top: 0px;">
                Fast Stroke Prediction
            </h2>
            <mat-form-field appearance="fill">
                <mat-label>Select Patient</mat-label>
                <mat-select matInput name="patient" (selectionChange)="patientSelected($event)">
                    <mat-option *ngFor="let patient of patients" [value]="patient">
                        <mat-icon *ngIf="patient.gender === 'male'">male</mat-icon>
                        <mat-icon *ngIf="patient.gender === 'female'">female</mat-icon>
                        <mat-icon *ngIf="patient.gender === 'undefined'">person</mat-icon>
                        {{ patient.firstname }} {{ patient.lastname }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>




        <mat-card class="patient-card">
            <mat-card-header>
                <mat-card-title-group>

                    <mat-card-title>
                        <mat-icon color="primary">person</mat-icon>
                        Enter patient info...</mat-card-title>
                </mat-card-title-group>
            </mat-card-header>
            <mat-card-content>
                <form #f="ngForm" class="patientForm">
                    <div class="row">
                        <mat-form-field appearance="fill">
                            <mat-label>Age</mat-label>
                            <input matInput [(ngModel)]="prediction.age" name="age" type="number" required>
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                            <mat-label>Gender</mat-label>
                            <mat-select matInput [(ngModel)]="prediction.gender" name="gender" required>
                                <mat-option *ngFor="let sex of genders" [value]="sex.val">
                                    {{sex.key}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <mat-form-field appearance="fill">
                            <mat-label>Hypertension</mat-label>
                            <mat-select [(ngModel)]="prediction.hypertension" name="hypertension" required>
                                <mat-option *ngFor="let option of ['True','False']" [value]="option">
                                    {{option}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                            <mat-label>Heart Disease</mat-label>
                            <mat-select [(ngModel)]="prediction.heart_disease" name="heart_disease" required>
                                <mat-option *ngFor="let option of ['True','False']" [value]="option">
                                    {{option}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <mat-form-field appearance="fill">
                            <mat-label>Ever married?</mat-label>
                            <mat-select [(ngModel)]="prediction.ever_married" name="ever_married" required>
                                <mat-option *ngFor="let option of ['Yes','No']" [value]="option">
                                    {{option}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                            <mat-label>Smoking Status</mat-label>
                            <mat-select [(ngModel)]="prediction.smoking_status" name="smoking_status" required>
                                <mat-option *ngFor="let option of ['never smoked','formely smoked','unknown']"
                                    [value]="option">
                                    {{option}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <mat-form-field appearance="fill">
                            <mat-label>Work Type</mat-label>
                            <mat-select [(ngModel)]="prediction.work_type" name="work_type" required>
                                <mat-option
                                    *ngFor="let option of ['Private','Self-employed','Govt_jov','children','Other']"
                                    [value]="option">
                                    {{option}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                            <mat-label>Residence Type</mat-label>
                            <mat-select [(ngModel)]="prediction.Residence_type" name="Residence_type" required>
                                <mat-option *ngFor="let option of ['Urban','Rural']" [value]="option">
                                    {{option}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <mat-form-field appearance="fill">
                            <mat-label>BMI</mat-label>
                            <input matInput [(ngModel)]="prediction.bmi" name="avg_glucose_level" type="number"
                                required>
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                            <mat-label>Average Glucose Level</mat-label>
                            <input matInput [(ngModel)]="prediction.avg_glucose_level" name="bmi" type="number"
                                placeholder="e.g. 1.2" required>
                        </mat-form-field>
                    </div>

                    <div class="button-container" *ngIf="!loadingPred">
                        <button mat-raised-button color="accent" (click)="predict()" class="my-button" color="primary"
                            [disabled]="!f.valid">Predict</button>
                    </div>
                </form>

                <mat-spinner *ngIf="loadingPred" diameter="50"></mat-spinner>
            </mat-card-content>
        </mat-card>
    </div>

    <div *ngIf="predictionResult">
        <div class="topbar predictionResult">
            <button mat-icon-button aria-label="Example icon button with a vertical three dot icon"
                (click)="resetPrediction()">
                <mat-icon>arrow_back</mat-icon>
            </button>
            <h2>
                Stroke Prediction Result
            </h2>

        </div>
        <mat-card class="info-card">
            <mat-card-content>
                <div class="container" *ngIf="this.predictionResult.result == 1">
                    <mat-icon>warning</mat-icon>
                    <p>Potential Stroke detected! Consider sending out Mobile Stroke Unit.</p>
                </div>
                <div class="container nostroke" *ngIf="this.predictionResult.result == 0">
                    <mat-icon>health_and_safety</mat-icon>
                    <p>No accute stroke risk detected. Perform further examination for more information.</p>
                </div>

            </mat-card-content>

        </mat-card>

        <button *ngIf="!showDetails" mat-stroked-button class="details-button" (click)="showDetails = true">
            <mat-icon>visibility</mat-icon>
            Show Details
        </button>
        <mat-card class="result-card" *ngIf="showDetails">

            <mat-card-content>
                <div class="result-scores">
                    <div>
                        <span class="val">{{ predictionResult.probability_stroke | percent: '1.2-2' }}</span><br>
                    </div>
                    <div class="negative">
                        <span class="val">{{ predictionResult.probability_no_stroke | percent: '1.2-2' }}</span><br>
                    </div>
                </div>
                <mat-progress-bar mode="determinate"
                    [value]="predictionResult.probability_stroke * 100"></mat-progress-bar>
                <div class="result-labels">
                    <div>
                        Probability Stroke
                    </div>
                    <div class="negative">
                        Probability No Stroke
                    </div>
                </div>
            </mat-card-content>

        </mat-card>
        <mat-card class="chart-card" *ngIf="showDetails">
            <mat-card-content>
                <app-chart [data]="predictionResult"></app-chart>
            </mat-card-content>

        </mat-card>
    </div>





</div>