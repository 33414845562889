<main>


  <section class="tableContainer">


    <section class="btnCentered">
      <button color="primary" mat-raised-button (click)="addCase()" id="addPatientButton">Add Case</button>
    </section>
    <ng-container *ngIf="loading">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </ng-container>
    <div class="mat-elevation-z4">
      <table mat-table [dataSource]="dataSource">



        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>
            ID
          </th>
          <td mat-cell *matCellDef="let case"> {{case.uid}} </td>
        </ng-container>
  
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>
            Date
          </th>
          <td mat-cell *matCellDef="let case"> {{case.date.toDate() | date: 'medium' }} </td>
        </ng-container>
  
        <ng-container matColumnDef="text">
          <th mat-header-cell *matHeaderCellDef>
            Notes
          </th>
          <td mat-cell *matCellDef="let case"> {{ case.notes ? case.notes : 'None' }} </td>
        </ng-container>
  
        <ng-container matColumnDef="patient">
          <th mat-header-cell *matHeaderCellDef>
            Patient
          </th>
          <td mat-cell *matCellDef="let case">
            {{ case.patient?.firstname ? case.patient.firstname + ' ' + case.patient.lastname : 'N/A' }}
          </td>
        </ng-container>
  
  
        <ng-container matColumnDef="menu">
          <th mat-header-cell *matHeaderCellDef class="maxWidthCell"></th>
          <td mat-cell *matCellDef="let case" (click)="$event.stopPropagation()">
            <ng-container>
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon fontSet="material-symbols-outlined">more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="editCase(case)">
                  <mat-icon>edit</mat-icon>
                  <span>Edit</span>
                </button>
                <button mat-menu-item (click)="deleteSafe(case)">
                  <mat-icon>delete</mat-icon>
                  <span>Delete</span>
                </button>
                <button mat-menu-item (click)="viewCase(case)">
                  <mat-icon>visibility</mat-icon>
                  <span>View</span>
                </button>
              </mat-menu>
            </ng-container>
          </td>
        </ng-container>
  
  
        <tr mat-header-row *matHeaderRowDef="['id','date','text','patient','menu']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['id','date','text','patient','menu'];" class="case-row"
          routerLink="/cases/{{row.uid}}/workbench"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell no-data-cell" [attr.colspan]="4">
            No case data found
          </td>
        </tr>
      </table>
      <mat-paginator [pageSizeOptions]="[25, 100, 200,500,1000]" showFirstLastButtons aria-label="Select page of cases">
      </mat-paginator>
    </div>
    
  </section>



</main>