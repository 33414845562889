
  <div class="main">
      <mat-card class="mat-elevation-z12">
          <mat-card-header>
            <mat-card-title-group>
              <mat-card-title>
                <h3>{{title}}</h3>
              </mat-card-title>
            </mat-card-title-group>
          </mat-card-header>
          <mat-card-content>
            <ng-content></ng-content>
          </mat-card-content>
      </mat-card>
  </div>