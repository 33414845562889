<!-- Applying the mat-tyography class adds styles for native elements. -->

<div class="root-container">
  <div class="left-container">
    <mat-card class="patient-data-card">
      <mat-card-header>
        <mat-card-title-group>
          <mat-card-title>Case Information</mat-card-title>
          <mat-icon color="primary">info</mat-icon>
        </mat-card-title-group>
      </mat-card-header>
      <mat-card-content>
        <app-case-info [case]="case"></app-case-info>
      </mat-card-content>
    </mat-card>
    <mat-card class="dicom-files-card">
      <mat-card-header>
        <mat-card-title-group>
          <mat-card-title>Scan Information</mat-card-title>
          <mat-icon color="primary" fontSet="material-symbols-outlined">radiology</mat-icon>
        </mat-card-title-group>
      </mat-card-header>
      <mat-card-content>
        <app-dicom-info></app-dicom-info>
      </mat-card-content>
    </mat-card>
    <mat-card class="dicom-files-card">
      <mat-card-header>
        <mat-card-title-group>
          <mat-card-title>Patient Information</mat-card-title>
          <mat-icon color="primary">person</mat-icon>
        </mat-card-title-group>
      </mat-card-header>
      <mat-card-content>
        <app-patient-info [patient]="patient"></app-patient-info>
      </mat-card-content>
    </mat-card>

  </div>
  <div class="center-container">
    <mat-card class="dicom-files-card">
      <mat-card-content>
        <app-dwv *ngIf="!newDicomViewer"></app-dwv>
        <app-dicom-viewer *ngIf="newDicomViewer"></app-dicom-viewer>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="right-container">
    <mat-card class="patient-data-card">
      <mat-card-header>
        <mat-card-title-group>
          <mat-card-title>Advanced Analysis</mat-card-title>
          <mat-icon color="primary" fontSet="material-symbols-outlined">insights</mat-icon>
        </mat-card-title-group>
      </mat-card-header>
      <mat-card-content>
        <app-advanced-analysis></app-advanced-analysis>
      </mat-card-content>
    </mat-card>
    <mat-card class="patient-data-card">
      <mat-card-header>
        <mat-card-title-group>
          <mat-card-title>AI Models</mat-card-title>
          <mat-icon color="primary">memory</mat-icon>
        </mat-card-title-group>
      </mat-card-header>
      <mat-card-content>
        <app-ai-models [case]="case"></app-ai-models>
      </mat-card-content>
    </mat-card>
    <mat-card class="patient-data-card">
      <mat-card-header>
        <mat-card-title-group>
          <mat-card-title>AI Results</mat-card-title>
          <mat-icon color="primary" fontSet="material-symbols-outlined">analytics</mat-icon>
        </mat-card-title-group>
      </mat-card-header>
      <mat-card-content>
        <app-ai-results></app-ai-results>
      </mat-card-content>
    </mat-card>
  </div>
</div>