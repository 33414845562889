<!-- <mat-sidenav-container> -->
<!-- Sidenav
  <mat-sidenav [style.marginTop.px]="mobileQuery.matches ? 56 : 0" #sidenav [fixedInViewport]="mobileQuery.matches"
    [mode]="mobileQuery.matches ? 'over' : 'over'" [(opened)]="opened">
    <mat-nav-list class="nav-list">
      <p class="model-heading" style="margin-top:0">CT-Scan AI Model</p>
      <mat-form-field appearance="fill" class="model-select">
        <mat-label>Select Prediction Model</mat-label>
        <mat-select [(ngModel)]="selectedValue" name="model">
          <mat-option *ngFor="let model of models" [value]="model.value">
            {{model.viewValue}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div class="predict-button-container" *ngIf="!modelRunning">
        <button mat-raised-button color="primary" (click)="runPrediction()"
          [disabled]="!selectedValue || !selectedFiles || selectedFiles!.length != 1">Make Prediction</button>
      </div>

      <ng-container *ngIf="modelRunning">
        <mat-spinner diameter="50"></mat-spinner>
        <p style="text-align:center">Making Prediction...</p>
      </ng-container>

      <div class="stroke-predict-button-container">
        <p class="model-heading">Tabular AI Model</p>
        <div>
          <button mat-raised-button color="primary" (click)="strokePrediction()">Open Dialog</button>
        </div>
      </div>
    </mat-nav-list>




  </mat-sidenav> -->
<!-- <mat-sidenav-content>
    <div id="dwv">
      <button *ngIf="mobileQuery.matches" mat-icon-button (click)="sidenav.toggle()"
        style="margin-right: 16px;"><mat-icon>apps</mat-icon></button>
      <div class="button-row">
        <mat-button-toggle-group name="tool" [disabled]="!dataLoaded">
          <mat-button-toggle value="{{ tool }}" color="primary" *ngFor="let tool of toolNames" title="{{ tool }}"
            (click)="onChangeTool(tool)" [disabled]="!dataLoaded || !canRunTool(tool)">
            <mat-icon>{{ getToolIcon(tool) }}</mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>

        <mat-button-toggle-group name="reset" [disabled]="!dataLoaded" (change)="onSingleToogleChange($event)">
          <mat-button-toggle color="primary" title="Reset" (click)="onReset()">
            <mat-icon>refresh</mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>

        <mat-button-toggle-group name="reset" [disabled]="!dataLoaded" (change)="onSingleToogleChange($event)">
          <mat-button-toggle color="primary" title="Toggle Orientation" (click)="toggleOrientation()">
            <mat-icon>cameraswitch</mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>

        <mat-button-toggle-group name="tags" [disabled]="!dataLoaded" (change)="onSingleToogleChange($event)">
          <mat-button-toggle color="primary" title="Tags" (click)="openTagsDialog()">
            <mat-icon>library_books</mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div id="layerGroup0" class="layerGroup">
        <div id="dropBox"></div>
      </div>
    </div>
  </mat-sidenav-content> -->
<!-- Main content -->

<!-- </mat-sidenav-container> -->

<div id="dwv">
  <div class="button-row">
    <div class="first-button-group">
      <mat-button-toggle-group name="tool" [disabled]="!dataLoaded">
        <mat-button-toggle value="{{ tool }}" color="primary" *ngFor="let tool of toolNames" title="{{ tool }}"
          (click)="onChangeTool(tool)" [disabled]="!dataLoaded || !canRunTool(tool)">
          <mat-icon>{{ getToolIcon(tool) }}</mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>

      <mat-button-toggle-group name="reset" [disabled]="!dataLoaded" (change)="onSingleToogleChange($event)">
        <mat-button-toggle color="primary" title="Reset" (click)="onReset()">
          <mat-icon>refresh</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle color="primary" title="Toggle Orientation" (click)="toggleOrientation()">
          <mat-icon>cameraswitch</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle color="primary" title="Tags" (click)="openTagsDialog()">
          <mat-icon>library_books</mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>


    <mat-form-field appearance="fill" class="model-field">
      <mat-label>Select Explanation Model</mat-label>
      <mat-select matInput name="aiModels" (selectionChange)="modelSelected($event)" [(ngModel)]="selectedModelValue">
        <mat-option *ngFor="let model of dicomsService.availableModels$.value" [value]="model.value">
          {{model.key}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="dwv-wrapper">
    <div id="layerGroup0" class="layerGroup" [style.visibility]="isLoading ? 'hidden' : 'visible'">
      <!-- <mat-slider vertical="true">
      <input matSliderThumb>
    </mat-slider> -->

    </div>

    <!-- <mat-slider width="10">
      <input matSliderThumb>
    </mat-slider> -->


    <mat-spinner *ngIf="isLoading" id="viewer-spinner" diameter="70"></mat-spinner>
  </div>
  <div *ngIf="currentPos" class="bottom-bar">
    X: {{ currentPos?.x | number: '1.1-2'}} px Y: {{ currentPos?.y | number: '1.1-2'}} px
  </div>
</div>