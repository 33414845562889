<div class="main">

  <div class="button_alignment">
    <div class="predict-button-container" *ngIf="!xaiRunning && !modelRunning ">
      <button mat-raised-button color="primary" (click)="runPredictionSimple()">Run Prediction</button>
    </div>

    <div class="predict-button-container" *ngIf="!xaiRunning && !modelRunning ">
      <button mat-raised-button color="primary" (click)="runExplanationSimple()">Run Explanation</button>
    </div>

  </div>


  <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Advanced Settings
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="form-container">
      <mat-form-field appearance="fill" class="model-select">
        <mat-label>Select Prediction Model</mat-label>
        <mat-select [(ngModel)]="selectedModel" name="model">
          <mat-option *ngFor="let model of aiModels" [value]="model.value">
            {{model.viewValue}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill" class="model-select">
        <mat-label>Select Explainable AI Model</mat-label>
        <mat-select [(ngModel)]="selectedExplainableAi" name="model">
          <mat-option *ngFor="let model of explainableAiModels" [value]="model.value">
            {{model.viewValue}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill" class="model-select">
        <mat-label>Select Explanation Complexity</mat-label>
        <mat-select [(ngModel)]="selectedComplexity" name="model">
          <mat-option *ngFor="let model of explainableAIComplexities" [value]="model.value">
            {{model.viewValue}}
          </mat-option>
        </mat-select>
      </mat-form-field>

    </div>

    <div class="button_alignment">
      <div class="predict-button-container" *ngIf="!xaiRunning && !modelRunning">
        <button mat-raised-button color="primary" (click)="runPrediction()"
          [disabled]="!selectedModel || !selectedFiles || selectedFiles!.length != 1">Predict</button>
      </div>

      <div class="predict-button-container" *ngIf="!xaiRunning && !modelRunning">
        <button mat-raised-button color="primary" (click)="runExplanation()"
          [disabled]="!selectedModel || !selectedExplainableAi || !selectedFiles || !selectedComplexity || selectedFiles!.length != 1">Explain</button>
      </div>
    </div>
  </mat-expansion-panel>

  <ng-container *ngIf="modelRunning">
    <mat-spinner diameter="50"></mat-spinner>
    <p style="text-align:center">Making Prediction...</p>
  </ng-container>

  <ng-container *ngIf="xaiRunning">
    <mat-spinner diameter="50"></mat-spinner>
    <p style="text-align:center">Making Explanation...</p>
  </ng-container>

</div>