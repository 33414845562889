<div class="main">
    <mat-list *ngIf="patient && (patient | json) != '{}'">
        <mat-list-item>
            <div>
                <span class="key">Name:</span>
                <span class="value">{{patient.lastname}}, {{patient.firstname}}</span>
            </div>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
            <div>
                <span class="key">Gender:</span>
                <span class="value">{{patient.gender == 'male' ? 'Male' : 'Female'}}</span>
            </div>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
            <div>
                <span class="key">Age:</span>
                <span class="value">{{patient.age}}</span>
            </div>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
            <div>
                <span class="key">BMI:</span>
                <span class="value">{{patient.bmi}}</span>
            </div>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
            <div>
                <span class="key">Avg. Glucose Level:</span>
                <span class="value">{{patient.averageGlucoseLevel}} mg/dl</span>
            </div>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
            <div>
                <span class="key">Heart Disease:</span>
                <span class="value">{{patient.heartDisease ? 'Yes' : 'No'}}</span>
            </div>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
            <div>
                <span class="key">Hypertension:</span>
                <span class="value">{{patient.hypertension ? 'Yes' : 'No'}}</span>
            </div>
        </mat-list-item>
    </mat-list>
    <div class="no-data" *ngIf="(patient | json) == '{}'">
        <p>
            No patient found
        </p>
    </div>
</div>
<mat-spinner *ngIf="!patient" diameter="50"></mat-spinner>