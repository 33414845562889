<!-- popup.component.html -->
<form [formGroup]="form" readonly>

  <mat-form-field appearance="outline">
      <mat-label for="first-name">First Name</mat-label>
      <input matInput placeholder="First Name" type="text" id="firstname" formControlName="firstname" readonly>
     
  </mat-form-field>
  <mat-form-field appearance="outline">
      <mat-label for="last-name">Last Name</mat-label>
      <input matInput placeholder="Last Name" type="text" id="lastname" formControlName="lastname"  readonly>
     
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label for="age">Age</mat-label>
    <input matInput placeholder="Age" type="number" id="age" formControlName="age"  readonly>
  
</mat-form-field>
<mat-form-field appearance="outline">
  <mat-label for="bmi">BMI</mat-label>
  <input matInput placeholder="None" type="number" id="bmi" formControlName="bmi"  readonly>

</mat-form-field>

<mat-form-field appearance="outline">
  <mat-label for="gender">Gender</mat-label>
  <input matInput placeholder="Gender" type="text" id="gender" formControlName="gender"  readonly>
 
</mat-form-field>

<mat-form-field appearance="outline">
  <mat-label for="work">Work Type</mat-label>
  <input matInput placeholder="None" type="text" id="work" formControlName="work"  readonly>
 
</mat-form-field>
<mat-form-field appearance="outline">
  <mat-label for="residency">Residency</mat-label>
  <input matInput placeholder="None" type="text" id="residency" formControlName="residency"  readonly>
 
</mat-form-field>

   
      
 
  <mat-form-field appearance="outline">
      <mat-label for="average-glucose-level">Average glucose level</mat-label>
      <input matInput placeholder="None" type="number" id="averageGlucoseLevel" formControlName="averageGlucoseLevel"  readonly>
      
  </mat-form-field>
  <section class="example-section">
    <mat-checkbox class="example-margin" id="hypertension" value="hypertension" formControlName="hypertension" (click)="doNothing()">Hypertension</mat-checkbox>
    </section>


    <section class="example-section">
      <mat-checkbox color="primary" class="example-margin" id="heartDisease" value="heartDisease" formControlName="heartDisease"  (click)="doNothing()">Heart Disease</mat-checkbox>
      </section>

      <section class="example-section">
        <mat-checkbox color="primary"class="example-margin" id="married" value="married" formControlName="married"  (click)="doNothing()">Married</mat-checkbox>
        </section>

        <section class="example-section">
          <mat-checkbox color="primary" class="example-margin" id="smoke" value="smoke" formControlName="smoke"  (click)="doNothing()">Smoke</mat-checkbox>
          </section>


 
</form>

