<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<mat-toolbar *ngIf="showHeader" class="header mat-elevation-z8" [color]="themeService.currentTheme.value == 'light' ? 'primary' : null">

    
    <div style="display: flex;">
        <button *ngIf="!reducedHeader" (click)="sidenavToggled.emit()" mat-icon-button class="menu-button" aria-label="Example icon-button with menu icon">
            <mat-icon>menu</mat-icon>
        </button>

        <span class="title-container" routerLink="/">
            <!-- <img src="/assets/icons/logo.svg" alt="logo" /> -->
            <span>brAInwatch</span>
        </span>
    </div>

    <div style="display: flex; align-items:center;" *ngIf="reducedHeader">
        <button mat-stroked-button [color]="themeService.currentTheme.value == 'light' ? null : 'primary'" style="margin-right: 16px;" routerLink="/login">
            Login
        </button>
        <app-theme-switch ></app-theme-switch>
    </div>
    
    <!-- <span class="example-spacer"></span> -->
    <div *ngIf="!reducedHeader">
        <button [matMenuTriggerFor]="profileMenu" mat-icon-button >
            <mat-icon>person</mat-icon>
        </button>
        <mat-menu #profileMenu="matMenu" xPosition="before" class="profileMenu" panelClass="profileMenu">
            <app-profile></app-profile>
        </mat-menu>
        
        <button mat-icon-button class="menu-button"
            [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu" xPosition="before">
            <a mat-menu-item routerLink="/settings">
                <mat-icon>settings</mat-icon> Settings
            </a>
            <app-theme-switch [forMenu]="true"></app-theme-switch>
            <a mat-menu-item (click)="authService.SignOut()">
                <mat-icon>logout</mat-icon> Logout
            </a>
        </mat-menu>
    </div>
    
</mat-toolbar>



<ng-content></ng-content>

<!-- <mat-toolbar color="primary" class="header">
    <span class="title-container">
        <img src="/assets/icons/logo.svg" alt="logo" />
        <span>BrainWatch</span>
    </span>
    <span class="example-spacer"></span>
    <button (click)="sidenav.toggle()" mat-icon-button class="example-icon"
        aria-label="Example icon-button with menu icon">
        <mat-icon>menu</mat-icon>
    </button>
</mat-toolbar> -->

<!-- <mat-sidenav-container>
    <mat-sidenav #sidenav [mode]="isBiggerScreen() ? 'over' : 'side'" [(opened)]="opened" [fixedInViewport]="true"
        [fixedTopGap]>
        <mat-nav-list>
            <a mat-list-item routerLink="/">
                <div style="display:flex;align-items: center;">
                    <mat-icon>workbench</mat-icon>
                    <span>Workbench</span>
                </div>

            </a>
            <a mat-list-item routerLink="/settings">
                <div style="display:flex;align-items: center;">
                    <mat-icon>settings</mat-icon>
                    <span>Settings</span>
                </div>
            </a>

        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
        <ng-content></ng-content>
    </mat-sidenav-content>

</mat-sidenav-container> -->